export default {
  'menu.welcome': '欢迎',
  'menu.home': '主页',
  'menu.dashboard': '仪表盘',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.operation': '广告/活动',
  'menu.operation.advertise': '广告管理',
  'menu.operation.activity': '活动管理'
}
